function monthTranslate(month: string) {
    let result = month;

    switch (month) {

        case 'Jan':
            result = 'Янв' ;
            break;
        case 'Feb':
            result = 'Фев' ;
            break;
        case 'Mar':
            result = 'Мар' ;
            break;
        case 'Apr':
            result = 'Апр' ;
            break;
        case 'May':
            result = 'Май' ;
            break;
        case 'Jun':
            result = 'Июн' ;
            break;
        case 'Jul':
            result = 'Июл' ;
            break;
        case 'Aug':
            result = 'Авг' ;
            break;
        case 'Sep':
            result = 'Сен' ;
            break;
        case 'Oct':
            result = 'Окт' ;
            break;
        case 'Nov':
            result = 'Ноя' ;
            break;
        case 'Dec':
            result = 'Дек' ;
            break;

    }

    return result;
}

export default function(label: string) {
    const month: any = label.match(/Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Nov|Dec/g);

    if (!month) {
        return label;
    }

    const translation: any = monthTranslate(month[0]);

    return label.replace(month, translation);
}
