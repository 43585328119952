

































import Vue from 'vue';

import Chart from './Chart.vue';
import colors from './colors';
import labels from '../labels';

export default Vue.extend({
    data() {
        return {
            localData: {} as any,
            chart: {} as any,
            showAll: false,
            colors,
            labels
        };
    },
    props: {
        stat: {
            required: true,
            type: Object
        }
    },
    created() {
        this.updateLocalData();
    },
    watch: {
        data: {
            handler() {
                this.updateLocalData();
            },
            deep: true
        },
        localData: {
            handler() {
                this.generateStatData();
            },
            deep: true
        }
    },
    methods: {
        updateLocalData() {
            this.localData = {};
            Object.keys(this.stat).forEach((key: string) => {
                this.$set(this.localData, key, this.localData[key] || true);
            });
            this.generateStatData();
        },
        changeStatus(key: string) {
            this.localData[key] = !this.localData[key];
        },
        showAllAction() {
            const localData = Object.keys(this.localData);

            localData.forEach((key: string) => {
                this.localData[key] = this.showAll;
            });

            this.generateStatData();
        },
        generateStatData() {
            const localChart = {};
            const localData = Object.keys(this.localData);

            localData.forEach((key: string) => {
                if (this.localData[key]) {
                    localChart[key] = this.stat[key];
                }
            });

            this.chart = localChart;

            if (localData.length > Object.keys(this.chart).length) {
                this.showAll = true;
            } else {
                this.showAll = false;
            }
        }
    },
    components: {
        Chart
    }
});
