
import Vue from 'vue';
import { Line } from 'vue-chartjs';

import labels from '../labels';
import borders from './colors';

import labelTranslate from '@/helpers/ChartjsTranslate';

export default Vue.extend({
    name: 'StatisticsChart',
    extends: Line,
    data() {
        return {
            chartData: {
                datasets: []
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'day',
                            tooltipFormat: 'D MMM YYYY',
                            displayFormats: {
                                day: 'D MMM'
                            },
                            distribution: 'series'
                        },
                        ticks: {
                            callback(label) {
                                return labelTranslate(label);
                            }
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        title(data) {
                            return labelTranslate(data[0].xLabel);
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            },
            labels,
            borders
        };
    },
    props: {
        data: {
            required: true,
            type: Object
        }
    },
    mounted() {
        this.generateData();
    },
    methods: {
        generateData() {
            const keysArr = Object.keys(this.data);
            const dataset: any = [];

            for (const key of keysArr) {
                const data: any = [];
                const items = this.data[key];

                for (const item of Object.keys(items).sort()) {
                    const value = {
                        t: new Date(item),
                        y: +items[item]
                    };

                    data.push(value);
                }

                const dataItem = {
                    label: this.labels[key] ? this.labels[key] : key,
                    borderColor: this.borders[key] ? this.borders[key] : '#CCC',
                    fill: false,
                    data
                };

                dataset.push(dataItem);
            }

            this.chartData.datasets = dataset;

            this.render();
        },
        render() {
            // @ts-ignore
            this.renderChart(this.chartData, this.options);
        }
    },
    watch: {
        data() {
            this.generateData();
        }
    }
});
