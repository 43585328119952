export default {
    clicks: '#4d62cc',
    registrations: '#1fc7cc',
    requests: '#ccbd3e',
    declines: '#cc2b4a',
    approves: '#69cc5b',
    issues: '#cc7d28',
    profit: '#19cc00',
    cr: '#736ecc',
    ar: '#984acc',
    epc: '#cc2b70'
};
